import * as Sentry from '@sentry/react'

import { getElopageConfig } from 'utils/elopageConfig.utils'

export const SENTRY_TRACES_SAMPLE_RATE = 0.01

// https://docs.sentry.io/platforms/javascript/guides/nextjs/configuration/filtering/#decluttering-sentry
// https://gist.github.com/impressiver/5092952
export const ignoreErrors = [
  // Random plugins/extensions
  'top.GLOBALS',
  // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
  'originalCreateNotification',
  'canvas.contentDocument',
  'MyApp_RemoveAllHighlights',
  'http://tt.epicplay.com',
  "Can't find variable: ZiteReader",
  'jigsaw is not defined',
  'ComboSearch is not defined',
  'http://loading.retry.widdit.com/',
  'atomicFindClose',
  // Facebook borked
  'fb_xd_fragment',
  // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
  // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
  'bmi_SafeAddOnload',
  'EBCallBackMessageReceived',
  // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
  'conduitPage',
  // Generic error code from errors outside the security sandbox
  // You can delete this if using raven.js > 1.0, which ignores these automatically.
  'Script error.',
  // Calling Facebook Pixel before it was initialized with Google Tag Manager
  'fbq is not defined',
  "Can't find variable: fbq",
  // Google Analytics
  'ga is not defined',
  // https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/16
  'Non-Error exception captured',
  'Non-Error promise rejection captured',
  // https://stackoverflow.com/questions/40398054/observe-on-mutationobserver-parameter-1-is-not-of-type-node
  'MutationObserver.observe must be an instance of Node',
  // sentry noise
  'Symbol.toPrimitive on cross-origin object',
  'window.Beacon is not a function',
  '_AutofillCallbackHandler',
  "Can't find variable: _AutofillCallbackHandler",
  "Can't find variable: hdmcid",
  "Can't find variable: pix",
  'hdmcid is not defined',
  'MutationObserver.observe: Argument 1 is not an object',
  // looks like someone made broken GTM code /s/markusvossde/payment/pjQ1tJy-879SVf6BBZez
  "null is not an object (evaluating 'inputs.checked = true')",
  'inputs is null',
  'TypeError: Illegal invocation',
  "Cannot read properties of undefined (reading 'digest')",
  // https://github.com/ymmooot/nuxt-jsonld/issues/1280 - looks like bug of Safari
  'r[0]["@context"]'
]

export const denyUrls = [
  // third party "marketing specialists"
  /\/(gtm|ga|analytics)\.js/i,
  // Facebook flakiness
  /graph\.facebook\.com/i,
  // Facebook blocked
  /connect\.facebook\.net\/en_US\/all\.js/i,
  // Woopra flakiness
  /eatdifferent\.com\.woopra-ns\.com/i,
  /static\.woopra\.com\/js\/woopra\.js/i,
  // Chrome extensions
  /extensions\//i,
  /^chrome:\/\//i,
  // Other plugins
  /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
  /webappstoolbarba\.texthelp\.com\//i,
  /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  // https://github.com/getsentry/sentry-javascript/discussions/5875
  /@webkit-masked-url/i,
  // third party packages
  /@geoapify/i,
  /consent\.cookiefirst\.com/i,
]

export const initSentry = () => {
  const dsn = getElopageConfig('sentryConfigCabinets')
  Sentry.init({
    dsn: dsn,
    maxBreadcrumbs: 50,
    debug: true,
    tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE,
    ignoreErrors,
    denyUrls,
  })
}

interface NotificationData {
  data: { api_endpoint: string }
  type: string
  title: string
}
